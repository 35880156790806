.about-stories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  img {
    min-width: 100%;
  }
  .frame-description {
    font-size: 15px;
    font-weight: 600;
    align-items: center;
    display: flex;
    transition: all 1s ease-out;
    &:hover {
      background-color: #134dbc;
      color: white;
    }
    p {
      padding: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .about-stories {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
