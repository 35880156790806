/* Contact US */
.home-contact-us {
  margin-bottom: 5px;
}
.contact-thumbs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  text-align: center;
  gap: 5%;
  align-items: stretch;
}
.contact-thumbs .org-social-link {
  /* height: 100px; */
  color: #2663d4;
  text-decoration: none;
  border: 2px solid #2663d4;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
  padding: 10px;
  border-top: none;
}
.contact-thumbs .org-social-link i {
  display: block;
  font-size: 50px;
  position: relative;
  top: -36px;
  z-index: 3;
}

.contact-thumbs .org-social-link::before,
.contact-thumbs .org-social-link::after {
  height: 2px;
  background: #2663d4;
  content: "";
  width: 43%;
  position: absolute;
  top: 0;
  z-index: 1;
}

.contact-thumbs .org-social-link.border-w-100::after,
.contact-thumbs .org-social-link.border-w-100::before {
  width: 50%;
}

.contact-thumbs .org-social-link::before {
  left: 0;
}
.contact-thumbs .org-social-link::after {
  right: 0;
}

@media screen and (max-width: 992px) {
  .navbar-collapse {
    padding-bottom: 20px;
  }
  .contact-thumbs {
    grid-template-columns: repeat(3, 1fr);
  }
  .sm-hide {
    display: none;
  }
  .progress-it-services .md-hide {
    padding-bottom: 30px;
  }
  .process-item {
    text-align: center;
  }
  .mission-vision {
    text-align: center;
  }
  .mission-vision h3:first-child {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .dot-indent {
    padding-bottom: 10px;
  }
  .dot-indent h4 {
    margin-bottom: 3px;
  }
  .md-end-sm-center {
    text-align: center !important;
    padding-top: 20px;
  }
  .small-col-reverse {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 992px) {
  .md-hide {
    display: none;
  }
  .md-text-end {
    text-align: right;
  }
}

@media screen and (max-width: 690px) {
  .contact-thumbs {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 500px) {
  .contact-thumbs {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 300px;
  }
  .contact-thumbs .org-social-link {
    margin-bottom: 5px;
  }
  .home-contact-us {
    margin-bottom: 25px;
  }
}