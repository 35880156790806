:root {
  --white: #ffffff;
  --brand: #134dbc;
  --brand-dark: #091d3e;
}

/* Welcome */
.home-welcome img {
  max-width: 100%;
}
.home-welcome-imgbox {
  position: relative;
}

.dot-indent {
  padding-left: 20px;
  position: relative;
}
.dot-indent::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: -5px;
  top: 10px;
  position: absolute;
  background-color: var(--brand);
  content: "";
}
.home-welcome-imgbox {
  .org-certification {
    display: flex;
    width: 48%;
    min-width: 215px;
    height: 138px;
    background: rgb(16, 62, 131);
    background: linear-gradient(
      90deg,
      rgba(16, 62, 131, 1) 27%,
      rgba(38, 99, 212, 1) 100%
    );
    position: absolute;
    bottom: 32px;
    left: 12px;
    color: var(--white);
    gap: 25px;
    align-items: center;
    padding: 10px 10px 10px 20px;
    i {
      font-size: 50px;
    }
  }
}
@media (max-width: 600px) {
  .home-welcome .lead {
    font-size: 1.15rem;
  }
}
