body {
  font-size: 100%;
  @media (max-width: 992px) {
    font-size: 98%;
  }
  @media (max-width: 768px) {
    font-size: 95%;
  }
  @media (max-width: 600px) {
    font-size: 90%;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 992px) {
  .navbar-collapse {
    padding-bottom: 20px;
  }
  .contact-thumbs {
    grid-template-columns: repeat(3, 1fr);
  }
  .sm-hide {
    display: none;
  }
  .progress-it-services .md-hide {
    padding-bottom: 30px;
  }
  .process-item {
    text-align: center;
  }
  .mission-vision {
    text-align: center;
  }
  .mission-vision h3:first-child {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .dot-indent {
    padding-bottom: 10px;
  }
  .dot-indent h4 {
    margin-bottom: 3px;
  }
  .md-end-sm-center {
    text-align: center;
    padding-top: 20px;
  }
  .small-col-reverse {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 992px) {
  .md-hide {
    display: none;
  }
  .md-text-end {
    text-align: right;
  }
}

@media screen and (max-width: 690px) {
  .contact-thumbs {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 500px) {
  .contact-thumbs {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 300px;
  }
  .contact-thumbs .org-social-link {
    margin-bottom: 5px;
  }
  .home-contact-us {
    margin-bottom: 25px;
  }
}

.animate-charcter {
  animation-duration: 10s;
  animation-name: textclip;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 10s;
}

@keyframes textclip {
  0% {
    color: #faf6e9 ;
  }
  20% {
    color: #ece8d9;
  }
  40% {
    color: #fffdf6;
  }
  60% {
    color: #add2c9;
  }
  80% {
    color: #F7F7F7 ;
  }
  95% {
    color: #FFE169;
  }
  100% {
    color: #fbfbfb;
  }
}
