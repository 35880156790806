.homeBanner {
  background-color: gray;
  max-width: 100%;
  overflow: hidden;
  min-height: 120px;
  .item {
    position: relative;
    figure {
      margin: 0;
    }
    img {
      object-fit: cover;
    }
    .txtAnimations {
      text-align: center;
      position: absolute;
      top: 0;
      display: grid;
      min-width: 100%;
      align-items: center;
      min-height: 100%;
      h3,
      h4 {
        color: white;
        position: relative;
      }
      h3 {
        font-weight: 600;
        font-size: 2rem;
      }
      h4 {
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }
}

@media (max-width: 1500px) {
  .about-banner {
    .bannerTitle {
      .about-banner-text {
        margin-top: -150px;
      }
    }
    .org-certification {
      bottom: -36px;
    }
  }
}

@media (max-width: 1200px) {
  .homeBanner .item .txtAnimations {
    h3 {
      font-weight: 600;
      font-size: 1.9rem;
    }
    h4 {
      font-weight: 400;
      font-size: 1.3rem;
    }
  }
}
@media (max-width: 800px) {
  .homeBanner .item .txtAnimations {
    h3 {
      font-weight: 600;
      font-size: 1.8rem;
    }
    h4 {
      font-weight: 400;
      font-size: 1.2rem;
    }
  }
}
@media (max-width: 600px) {
  .homeBanner .item .txtAnimations {
    h3 {
      font-size: 1.5rem;
    }
    h4 {
      font-size: 1rem;
    }
  }
}
