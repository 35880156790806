.career-banner {
  position: relative;
  min-height: 120px;
  img {
    width: 100%;
  }
  h2 {
    position: absolute;
    top: 49%;
    text-align: center;
    width: 100%;
    color: white;
    font-weight: bold;
    margin-top: -10px;
    .text-transition {
      display: inline-flex !important;
      width: 180px;
    }
  }
}

.explore-opportunities {
  .explore-opportunities-types {
    justify-content: center;
    gap: 20px;
    grid-template-columns: auto auto;
    display: grid;
    @media (max-width: 600px) {
      grid-template-columns: auto;
      figure {
        text-align: center;
      }
    }
  }
}
.opportunities-tile {
  border-radius: 8px;
  position: relative;
  display: grid;
  align-items: center;
  border: 1px solid #f6f6f6;
  overflow: hidden;
  padding: 0;
  color: white;

  figure {
    margin: 0;
  }
  h4,
  a {
    position: absolute;
    width: 100%;
  }
  h4 {
    text-align: center;
  }
  a {
    padding: 10px 15px;
    text-decoration: none;
    color: white;
    background: rgb(16, 62, 131);
    background: linear-gradient(
      90deg,
      rgba(16, 62, 131, 1) 0%,
      rgba(38, 99, 212, 0) 100%
    );
    font-size: 20px;
    font-weight: bold;
    bottom: 0;
  }
}

.animated {
  -webkit-animation: bounce 5s infinite;
  animation: bounce 5s both infinite;
}

@keyframes bounce {
  0% {
    -webkit-transform: translateX(-20px);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(200px);
    opacity: 0;
  }
}

.job-card {
  border-radius: 10px;
  border-width: 1px 1px 1px 4px;
  border-left-color: #2663d4;
  .country {
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
  }
  .bookmark {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
}

@media (max-width: 1200px) {
  .career-banner h2 {
    font-size: 34px;
    margin-top: -20px;
  }
}
@media (max-width: 800px) {
  .career-banner h2 {
    font-size: 30px;
    margin-top: -30px;
  }
}
@media (max-width: 768px) {
  .career-banner h2 {
    font-size: 25px;
    margin-top: -40px;
  }
}

@media (max-width: 600px) {
  .career-banner h2 {
    font-size: 28px;
    margin-top: -35px;
  }
}

@media (max-width: 575px) {
  .career-search {
    .col-auto {
      width: 100%;
      text-align: center;
    }
  }
}

@media (max-width: 500px) {
  .career-banner h2 {
    font-size: 25px;
    margin-top: -40px;
  }
  .career-search {
    .col-auto {
      width: 100%;
      text-align: center;
    }
  }
}
