/* New training page Style  */
.badge-background {
  background: #091d3e;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
}

.top-seached-category-wrap {
  display: grid;
  gap: 35px 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  align-items: stretch;
}

.top-seached-category {
  display: grid;
  text-decoration: none;
  color: inherit;
}
.top-seached-category figcaption {
  padding-top: 5px;
  font-weight: bold;
}

.top-seached-category img {
  border-radius: 5px;
  box-shadow: 1px 1px 4px 0px #00000040;
  padding: 20px;
  min-height: 100%;
  min-width: 100%;
  transition: transform 0.2s ease-in-out;
}
.top-seached-category img:hover {
  transform: scale(1.01);
  box-shadow: 2px 1px 8px 0px #00000040;
}

.owlNavCenter {
  position: relative;
  .item {
    figure {
      margin-bottom: 5px;
    }
    small {
      display: block;
      padding-bottom: 3px;
    }
  }
  .owl-nav {
    line-height: 30px;
    font-size: 75px;
    color: gray;
    
    .owl-prev {
      border-radius: 50%;
      position: absolute;
      top: calc(40% - 35px);
      margin-left: -25px;
    }

    .owl-next {
      border-radius: 50%;
      position: absolute;
      top: calc(40% - 35px);
      right: -10px;
    }
    button span {
      width: 50px;
      height: 50px;
      background-color: white;
      border-radius: 25px;
      display: block;
      border: 1px solid gray;
    }
  }
  .owl-dots {
    display: none;
  }
  .badge {
    background-color: #007bff;
    color: white;
  }
}

.rating {
  .heading {
    color: white;
    background-color: orange;
    padding: 1px 5px;
    border-radius: 5px;
    display: inline-block;
    letter-spacing: 1px;
    margin-right: 5px;
    .fa {
      color: white;
    }
  }
  .fa {
    font-size: 16px;
  }
  .checked {
    color: orange;
  }  
}


@media screen and (max-width: 992px) {
  .top-seached-category-wrap {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 690px) {
  .top-seached-category-wrap {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 500px) {
  .top-seached-category-wrap {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
