:root {
  --white: #ffffff;
  --brand: #134dbc;
  --brand-dark: #091d3e;
}

.brand-txt {
  color: var(--brand);
}

/* End of Navbar and Logo */

/* Banner */
.about-banner {
  position: relative;
  display: grid;
  .about-banner-img {
    width: 100%; /* Make the image fill the width of the parent */
    height: 100%; /* Make the image fill the height of the parent */
    object-fit: cover; /* Ensure the image covers the entire container */
    display: block;
    z-index: 2;
  }
  &::before {
    width: 100px;
    height: 50px;
    background: var(--brand);
    content: "";
    left: 0;
    top: 0;
    position: absolute;
  }
  @media screen and (max-width: 1224px) {
  }
  @media screen and (max-width: 992px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 768px) {
  }
  .bannerTitle {
    z-index: 1;
    align-items: center;
    display: flex;
    color: white;
    flex-shrink: 0;
    flex-grow: 1;
    min-height: 120px;
    > div {
      flex-shrink: 0;
      flex-grow: 1;
    }
    h2 {
      color: white;
      font-weight: 500;
    }
    p {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
  .bannerTitle {
    position: absolute;
    z-index: 3;
    display: grid;
    width: 100%;
    height: 100%;
    .about-banner-text {
      margin-top: -200px;
    }
  }
  .org-certification {
    display: flex;
    width: 210px;
    height: 210px;
    position: absolute;
    bottom: -25px;
    left: 8%;
    color: var(--white);
    align-items: center;
    z-index: 3;
    img {
      position: absolute;
    }
    caption {
      color: white;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      z-index: 2;
      @media (max-width: 1224px) {
        font-size: 22px;
        line-height: 22px;
      }
      @media (max-width: 992px) {
        font-size: 19px;
        line-height: 19px;
      }
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 18px;
      }
      @media (max-width: 768px) {
        font-size: 15px;
        line-height: 15px;
      }
      @media (max-width: 500px) {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
  .org-certification i {
    font-size: 50px;
  }
}

.offeredServices {
  .item {
    padding: 10px 0;
    figure {
      img {
        height: 38px;
      }
    }
  }
}
.rectangle .text-wrapper {
  position: absolute;
  width: 191px;
  top: 146px;
  left: 73px;
  font-family: "Roboto-SemiBold", Helvetica;
  font-weight: 600;
  color: #000000;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.rectangle .div {
  position: absolute;
  width: 260px;
  top: 212px;
  left: 39px;
  font-family: "Roboto-Regular", Helvetica;
  font-weight: 400;
  color: #383d3d;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.rectangle .vector {
  position: absolute;
  width: 60px;
  height: 38px;
  top: 71px;
  left: 139px;
}

.owlNavCenter {
  position: relative;
  .item {
    figure {
      margin-bottom: 5px;
    }
    small {
      display: block;
      padding-bottom: 3px;
    }
  }
  .owl-nav {
    line-height: 30px;
    font-size: 75px;
    color: gray;

    .owl-prev {
      border-radius: 50%;
      position: absolute;
      top: calc(40% - 35px);
      margin-left: -25px;
    }

    .owl-next {
      border-radius: 50%;
      position: absolute;
      top: calc(40% - 35px);
      right: -10px;
    }
    button {
      span {
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 25px;
        display: block;
        border: 2px solid gray;
        line-height: 23px;
      }
      &.owl-prev span {
        padding-right: 3px;
      }
      &.owl-next span {
        padding-left: 3px;
      }
    }
  }
  .owl-dots {
    display: none;
  }
  .badge {
    background-color: #007bff;
    color: white;
  }
}
.box {
  width: 60px;
  height: 38px;
}

.box .vector {
  position: fixed;
  width: 60px;
  height: 38px;
  left: 0;
}

// Media Queries
@media (max-width: 1500px) {
  .about-banner {
    .bannerTitle {
      .about-banner-text {
        margin-top: -150px;
      }
    }
    .org-certification {
      bottom: -36px;
    }
  }
}

@media (max-width: 1400px) {
  .about-banner {
    .org-certification {
      bottom: -46px;
    }
  }
}
@media (max-width: 1300px) {
  .about-banner {
    .bannerTitle {
    }
    .org-certification {
      width: 190px;
      left: 7%;
      bottom: -50px;
    }
  }
}
@media (max-width: 1200px) {
  .about-banner {
    .bannerTitle {
    }
    .org-certification {
      bottom: -60px;
    }
  }
}
@media (max-width: 1100px) {
  .about-banner {
    .bannerTitle {
    }
    .org-certification {
      bottom: -70px;
    }
  }
}
@media (max-width: 1000px) {
  .about-banner {
    .bannerTitle {
      .about-banner-text {
        margin-top: -100px;
      }
      h2 {
        font-size: 35px;
      }
      p {
        font-size: 1.4rem;
      }
    }
    .org-certification {
      left: 5%;
      bottom: -80px;
    }
  }
}
@media (max-width: 900px) {
  .about-banner {
    .bannerTitle {
      h2 {
        font-size: 32px;
      }
      p {
        font-size: 1.3rem;
      }
    }
    .org-certification {
      width: 175px;
      bottom: -80px;
    }
  }
}
@media (max-width: 800px) {
  .about-banner {
    .bannerTitle {
      h2 {
        font-size: 30px;
      }
      p {
        font-size: 1.3rem;
      }
    }
    .org-certification {
      width: 150px;
      bottom: -82px;
    }
  }
}
@media (max-width: 700px) {
  .about-banner {
    .bannerTitle {
      .about-banner-text {
        margin-top: -85px;
      }
      h2 {
        font-size: 28px;
      }
      p {
        font-size: 1.2rem;
      }
    }
    .org-certification {
      width: 145px;
      bottom: -89px;
    }
  }
}
@media (max-width: 600px) {
  .about-banner {
    .bannerTitle {
      h2 {
        font-size: 24px;
        line-height: 24px;
      }
      p {
        font-size: 1.2rem;
        line-height: 1.2rem;
      }
    }
    .org-certification {
      width: 120px;
      bottom: -95px;
    }
  }
}
@media (max-width: 500px) {
  .about-banner {
    .bannerTitle {
      .about-banner-text {
        margin-top: -35px;
      }
      h2 {
        font-size: 21px;
      }
      p {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
    .org-certification {
      bottom: -111px;
      width: 95px;
    }
  }
}
