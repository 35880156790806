
/* Training */
.sns-training {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .sns-training h3 {
    padding-bottom: 20px;
  }
  .sns-training .owl-stage {
    display: flex;
  }
  
  .sns-training .owl-item {
    text-align: justify;
    border: 2px solid var(--brand-dark);
    border-radius: 5px;
    display: flex;
    padding: 10px;
    align-self: stretch;
  }
  .sns-training .owl-item:hover {
    background: rgb(16, 62, 131);
    background: linear-gradient(
      90deg,
      rgba(16, 62, 131, 1) 0%,
      rgba(38, 99, 212, 1) 100%
    );
    color: var(--white);
  }
  
  .item .hover-image {
    display: none !important;
  }
  .item:hover .normal-image {
    display: none;
  }
  .item:hover .hover-image {
    display: block !important;
  }

  .owl-carousel .owl-stage-outer {
    padding-left: 2px;
  }