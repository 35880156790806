.progress {
  height: 300px;
}

.progress > svg {
  height: 100%;
  display: block;
}

.light-blue {
  background-color: #d6f2ff;
  color: #0163a3 !important;
}

.bright-blue {
  background: #66c5ef;
}

.teal {
  background-color: #57a9af;
  color: white;
}

.process__step-number {
  font-family: fantasy;
}
