.home-call-back {
  img {
    max-width: 80%;
  }
  .form-control {
    border: none;
    border-bottom: 1px solid #999;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    background: none;
  }
  .form-control:active,
  .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000;
  }

  .col-form-label {
    color: #8b8b8b;
    font-size: 13px;
    font-weight: 500;
  }

  .btn,
  .form-control,
  .custom-select {
    height: 35px;
    border-radius: 0;
  }

  .custom-select {
    border: none;
    border-bottom: 1px solid #999;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
  }
  .custom-select:active,
  .custom-select:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #000;
  }

  .btn {
    border: none;
    border-radius: 0;
    font-size: 11px;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    border-radius: 30px !important;
  }
  .btn:active,
  .btn:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  // .contact-wrap {
  //   -webkit-box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.05);
  //   box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.05);
  //   border: 1px solid #efefef;
  // }
  .contact-wrap .col-form-label {
    font-size: 15px;
    color: #333;
    display: inline-block;
    padding: 0;
  }
  .contact-wrap .form,
  .contact-wrap .contact-info {
    padding: 40px;
  }
  .contact-wrap .contact-info {
    color: rgba(255, 255, 255, 0.5);
  }
  .contact-wrap .contact-info ul li {
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.5);
  }
  .contact-wrap .contact-info ul li .wrap-icon {
    font-size: 20px;
    color: #fff;
    margin-top: 5px;
  }
  .contact-wrap .form {
    background: #fff;
  }
  .contact-wrap .form h3 {
    color: #000;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .contact-wrap .contact-info {
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .contact-wrap .contact-info a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .contact-wrap .contact-info h3 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 30px;
  }

 .error {
    font-size: 12px;
    color: red;
  }

  #message {
    resize: vertical;
  }

  .form-message-success {
    color: #55a44e;
    font-size: 18px;
    font-weight: bold;
  }

  .submitting {
    float: left;
    width: 100%;
    padding: 10px 0;
    display: none;
    font-weight: bold;
    font-size: 12px;
    color: #000;
  }
}

@media (max-width: 1199.98px) {
  .contact-wrap .contact-info {
    height: 400px !important;
  }
}
