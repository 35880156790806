:root {
  --white: #ffffff;
  --brand: #134dbc;
  --brand-dark: #091d3e;
}

* {
  margin: 0;
  outline: 0;
  border: none;
  padding: 0;
}
img {
  max-width: 100%;
}

.brand-txt {
  color: var(--brand);
}
.md-end-sm-center {
  text-align: end;
}

body h2 {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

body h3 {
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.process h4 {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

/* Social Links */
.social-nav {
  background-color: var(--brand-dark);
}

.sns-social-links {
  padding: 5px 10px;
  display: grid;
  gap: 20px;
  justify-content: end;
  grid-auto-flow: column;
}

.sns-social-links a {
  color: var(--white);
  font-size: 20px;
  justify-content: end;
}
/* End Social Links */

/* Navbar and Logo */
.navbar {
  padding-bottom: 0;
  padding-top: 0;
}
.navbar-brand {
  background-color: var(--brand);
  padding: 1px 28px 0 45px;
  margin-left: -1.5rem;
  position: relative;
  border-bottom: 0.6rem solid var(--brand);
  position: relative;
  text-align: center;
}
.navbar-brand .bg {
  position: absolute;
  width: 100%;
  height: 102px;
  left: 0;
  top: -44px;
  background-color: var(--brand);
  @media screen and (max-width: 992px) {
    height: 93px;
  }
}

.navbar-brand::before {
  position: absolute;
  right: -20px;
  top: -50px;
  content: "";
  border-right: 23px solid transparent;
  border-bottom: 49px solid var(--brand);
  opacity: 0.7;
}

.dropdown-toggle::after {
  margin-left: 0.455em;
  vertical-align: 0.055em;
}
@media screen and (max-width: 992px) {
  .navbar-brand::before {
    border-bottom: 49px solid var(--brand);
    right: -18px;
  }
}

.navbar-brand img {
  margin-top: -32px;
  z-index: 999;
  position: relative;
  border-radius: 50%;
  border: 4px solid white;
  background-color: white;
}
.navbar-nav .nav-link {
  font-weight: 500;
  @media screen and (max-width: 992px) {
    text-align: center;
  }
}

/* Navbar and Logo */
#responsive-navbar-nav {
  justify-content: space-between;
  padding: 10px 0;
}
.navbar-toggler {
  position: absolute;
  top: 9px;
  right: 11px;
}
.navbar-nav .nav-link.active {
  color: #2663d4;
}

@media screen and (max-width: 992px) {
  .header-search {
    /* flex-direction: column; */
    padding: 0 5%;
    justify-content: center;
    flex-wrap: wrap;
    .vr {
      display: none;
    }
    .form-control {
      max-width: 400px;
    }
  }
}
